export const HOME = "/index.html"
export const ABOUT = "/about"
export const MODDED_WEAPON_BUILDER = "/moddedweaponbuilder"
export const DAMAGE_SIMULATOR = "/armordamagecalculator" 

export const DATA_SHEETS = "/datasheets"
export const DATA_SHEETS_AMMO = `${DATA_SHEETS}/ammo`
export const DATA_SHEETS_ARMOR = `${DATA_SHEETS}/armor`
export const DATA_SHEETS_WEAPONS = `${DATA_SHEETS}/weapons`

export const ARMOR_VS_AMMO = `${DATA_SHEETS}/armor_vs_ammo`
export const AMMO_VS_ARMOR = `${DATA_SHEETS}/ammo_vs_armor`

export const AMMO_EFFECTIVENESS_CHART = `${DATA_SHEETS}/ammo_effectiveness`